import React from 'react'
import Layout from '../../components/containers/layout'
import Seo from "../../components/seo/seo"
import Navbar from '../../components/nav/navbar'
import Footer from '../../components/nav/footer'
import Hr from '../../components/hr/hr.js'
import Surgery from '../../components/containers/services/chirurgia.js'
import { getSrc } from "gatsby-plugin-image"

const seoImage = getSrc("../../static/oferta/chirurgia.jpg")

const Chirurgia = () => (
  <Layout>
    <Seo title="Weterynarz - chirurgia"
         description="Chirurgia miękka tj. na terenie klatki piersiowej i jamy brzusznej oraz twarda: zabiegi ortopedyczne w obrębie kości kończyn oraz kręgosłupa"
         image={ seoImage }
    />
    <Navbar />
    <Hr nazwa={ "Chirurgia" }/>
    <Surgery />
    <Footer />
  </Layout>
)
export default Chirurgia;
