import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.css'
import { StaticImage } from "gatsby-plugin-image"

const path = "../../../../static/oferta/"

const Surgery = () => (
  <Container className="text_container">
  <StaticImage variant="top" src={path + "chirurgia.jpg"} alt="Chirurgia" height={400}/>
    <div className="divider"></div>
    <p>
    Wykwalifikowani specjaliści w naszej przychodni wykonują nawet najbardziej skomplikowane zabiegi u
    Waszych przyjaciół pod kontrolą anestezjologiczną. Specjalizujemy się w chirurgii miękkiej tj. na
    terenie klatki piersiowej i jamy brzusznej oraz twardej: zabiegi ortopedyczne w obrębie kości kończyn oraz kręgosłupa.
    </p>
  </Container>
);

export default Surgery;
